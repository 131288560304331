@font-face {
  font-family: "Inter Black";
  src: url('./Inter-Black.ttf') format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Inter Bold";
  src: url('./Inter-Bold.ttf') format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Inter Extra Bold";
  src: url('./Inter-ExtraBold.ttf') format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Inter Extra Light";
  src: url('./Inter-ExtraLight.ttf') format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Inter Light";
  src: url('./Inter-Light.ttf') format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Inter Medium";
  src: url('./Inter-Medium.ttf') format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Inter Regular";
  src: url('./Inter-Regular.ttf') format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Inter Semi Bold";
  src: url('./Inter-SemiBold.ttf') format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Inter Thin";
  src: url('./Inter-Thin.ttf') format("truetype");
  font-style: normal;
}